.btnPrev,
.btnNext {
  height: 44px;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  transform: translateY(-24px);
  touch-action: manipulation;
  position: absolute;
  top: 50%;
  z-index: var(--z-index-2x);
  object-fit: cover;
  overflow: hidden;
  color: var(--color-white);
  font-size: var(--font-size-xl);
  border-radius: 0;
  padding: 0;
}
.btnPrev {
  left: 0;
}

.btnNext {
  right: 0;
}

.btnPrev::before,
.btnNext::before {
  content: '';
  position: absolute;
  inset: 0;
}

.btnNext svg {
  height: 44px;
  position: relative;
  z-index: var(--z-index-1x);
  width: 36px;
  color: var(--color-white);
  background-color: var(--color-black);
  opacity: 0.6;
  padding: var(--space-2x) var(--space-3x);
  border-radius: var(--border-radius-6x);
  margin-right: var(--space-1x);
}

.btnPrev svg {
  height: 44px;
  position: relative;
  z-index: var(--z-index-1x);
  width: 36px;
  color: var(--color-white);
  background-color: var(--color-black);
  opacity: 0.6;
  padding: var(--space-2x) var(--space-3x);
  border-radius: var(--border-radius-6x);
  margin-left: var(--space-1x);
}

.btnNext svg,
.btnPrev svg {
  &:hover {
    background-color: var(--color-black);
  }
}
